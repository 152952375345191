import React from 'react'
import PropTypes from 'prop-types'
import { Avatar } from '@mui/material'

UiAvatar.propTypes = {
  name: PropTypes.string,
}

UiAvatar.defaultProps = {
  name: '',
}

function UiAvatar({ name, src, size = 24, ...other }) {
  function stringToColor(string) {
    let hash = 0
    let i

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = '#'

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff
      color += `00${value.toString(16)}`.slice(-2)
    }

    return color
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0]?.[0] || ''}${name.split(' ')[1]?.[0] || ''}`,
    }
  }

  function convertSrc(src) {
    if (src?.indexOf('http') > -1) {
      return src
    }

    return ''
  }

  if (src || name) {
    return <Avatar {...stringAvatar(name)} src={convertSrc(src)} sx={{ width: size, height: size }} {...other} />
  }

  return <Avatar sx={{ width: size, height: size }} {...other} />
}

export default UiAvatar
